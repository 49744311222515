import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"

import SEO from "../components/Seo"
import Layout from "../components/Layout"
import { PageProps } from "../ts/interfaces"
import Hero from "../components/sections/Hero"
import Section from "../components/Sections"
import { useTrackProductView } from "../hooks/useTrackProductView"
import { runFreshDeskLogic, runFreshChatLogic } from "../utils/freshDesk"
import styled from "styled-components"
import { Button } from "../components/ui/Button"
import { LoadCampaignAffiliateData } from "../utils/campaignAffiliateData"
// import loadable from "@loadable/component"
// const Section = loadable(
//   () => import(/* webpackPrefetch: true */ "../components/Sections")
//)

declare global {
  interface Window {
    FreshworksWidget: any
  }
}

const Page = ({
  pageContext,
  data: {
    strapi: { freshDeskWidget, freshChatWidget, page },
  },
}: {
  pageContext: {
    canonicalUrls?: string[]
    slug: string
  }
  data: {
    strapi: {
      page: PageProps
      freshDeskWidget: {
        freshDeskTestId?: string
        freshDeskId?: string
        openOnLoad: boolean
        pages: {
          slug?: string
        }[]
      }
      freshChatWidget: {
        freshChatToken?: string
        openOnLoad: boolean
        pages: {
          slug?: string
        }[]
      }
    }
  }
}) => {
  LoadCampaignAffiliateData()
  const { sections, trackProductView } = page
  const {
    freshDeskId,
    freshDeskTestId,
    pages: freshDeskPages,
    openOnLoad: freshDeskOpenOnLoad,
  } = freshDeskWidget
  const {
    freshChatToken,
    pages: freshChatPages,
    openOnLoad: freshChatOpenOnLoad,
  } = freshChatWidget

  // const [delayedIndex, setDelayed] = useState(0)

  const hero = sections.find(
    data => data.__typename === "STRAPI_ComponentZonesHeroZone"
  )

  const hasHero = hero !== null && hero !== undefined

  let triggerProductView: Function
  if (trackProductView) {
    triggerProductView = useTrackProductView(trackProductView)
  }

  useEffect(() => {
    const freshDeskPagesFormatted = freshDeskPages.map(page => page.slug!) || []
    runFreshDeskLogic({
      currentPageSlug: pageContext.slug!,
      freshDeskPages: freshDeskPagesFormatted,
      freshDeskId,
      freshDeskTestId,
      openOnLoad: freshDeskOpenOnLoad,
    })

    const freshChatPagesFormatted = freshChatPages.map(page => page.slug!) || []

    runFreshChatLogic({
      currentPageSlug: pageContext.slug!,
      freshDeskPages: freshChatPagesFormatted,
      freshChatToken,
      openOnLoad: freshChatOpenOnLoad,
    })
    if (trackProductView) {
      setTimeout(() => {
        triggerProductView()
      }, 100)
    }
  }, [])

  return (
    <Layout hasHero={hasHero} showStickFooter={true}>
      <SEO
        title={page.title}
        metaTitle={page.seo.metaTitle}
        description={page.seo.metaDescription}
        image={getSrc(
          page.seo.metaImage?.imageFile.childImageSharp.gatsbyImageData
        )}
        canonicalUrls={pageContext.canonicalUrls}
      />

      {hasHero && <Hero {...hero} />}

      {sections.map((section, index) => (
        <Section
          key={`${section.__typename}-${section.id}`}
          section={{
            ...section,
          }}
          delayIndex={hasHero ? index : undefined}
        />
      ))}
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query PageById($id: ID!) {
    strapi {
      page(id: $id) {
        id
        title
        slug
        trackProductView {
          id
          name
          stripeId
          stripeTestId
        }
        seo {
          metaTitle
          metaDescription
          metaImage {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: NONE
                  layout: FIXED
                  quality: 80
                  width: 1200
                )
              }
            }
          }
        }
        sections {
          __typename
          ... on STRAPI_ComponentZonesMembershipZone {
            id
            membership {
              bulletPoints {
                text
              }
              featuredText
              period
              title
              product {
                stripeId
                stripeTestId
                isSubscription
                subscriptionPeriod
                isBilledMonthly
                isBilledYearly
              }
            }
            buttonText
          }
          ... on STRAPI_ComponentZonesCarouselZone {
            id
            backgroundColor
            marginTop
            marginBottom
            paddingTop
            paddingBottom
            images {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesBannerZone {
            id
            banner {
              linkToPage {
                id
                slug
              }
              linkToLandingPage {
                id
                slug
              }
              linkToExternalPage
              backgroundColor
              marginTop
              marginBottom
              paddingTop
              paddingBottom
              image {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesHeroZone {
            id
            heading {
              text
              colour
              align
            }
            subheading {
              text
              colour
              align
            }
            more
            contentAlign
            displayType
            backgroundImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
            mobileBackgroundImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 30
                    breakpoints: [400, 600, 750, 1080]
                  )
                }
              }
            }
            featuredVideo {
              youtubeId
              youtubeMobileId
            }
            button {
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesFormZone {
            id
            type
            titleAlign
            formTitle
            formSubTitle
            buttonText
            buttonType
            successMessage
            emailSignupNameOptions
            successRedirect {
              navItemPage {
                id
                title
                slug
              }
              navItemLandingPage {
                id
                title
                slug
              }
            }
            columns

            card {
              id
              title
              description
              iconName
              iconType
              align
              media {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              productPriceDisplay {
                CAD_List_Price
                CAD_Discount
                USD_List_Price
                USD_Discount
                depositHeading
                priceHeading
                stripeId
                stripeTestId
                inventoryBreakpoints {
                  id
                  breakpointText
                  breakpointStart
                  breakpointEnd
                  textColor
                }
              }
              productDisplayEnum
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
            core {
              anchorTag
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesCardZone {
            id
            columns
            maxWidth
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            card {
              id
              title
              description
              iconName
              iconType
              align
              media {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: CONSTRAINED
                      quality: 80
                    )
                  }
                }
              }
              productPriceDisplay {
                CAD_List_Price
                CAD_Discount
                USD_List_Price
                USD_Discount
                depositHeading
                priceHeading
                stripeId
                stripeTestId
                inventoryBreakpoints {
                  id
                  breakpointText
                  breakpointStart
                  breakpointEnd
                  textColor
                }
              }
              productDisplayEnum
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesSpecZone {
            id
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            specCard {
              id
              title
              description
              countTo
              countSuffix
              strikethrough
              countPrefix
              countLabel
            }
          }
          ... on STRAPI_ComponentZonesStepsZone {
            id
            description
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            steps {
              title
              iconName
              iconType
              state
            }
            buttons {
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesSimpleContentZone {
            id
            content
            align
            alignButtons
            maxWidth
            largeFormat
            inlineMedia {
              referenceName
              rounded
              align
              width
              youtubeId
              youtubeMobileId
              image {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              mobileImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            buttons {
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }

            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            featuredImage {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 70
                  )
                }
              }
            }
            featuredVideo {
              youtubeId
              youtubeMobileId
            }
          }
          ... on STRAPI_ComponentZonesTestimonialsZone {
            id
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            version
            start
            testimonials {
              id
              avatar {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FIXED
                      quality: 80
                      height: 132
                      width: 132
                    )
                  }
                }
              }
              author
              company
              quote
            }
          }
          ... on STRAPI_ComponentZonesFeaturesZone {
            id
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            description
            image {
              url
              alternativeText
              imageFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                    quality: 80
                  )
                }
              }
            }
            cards {
              id
              title
              iconType
              iconName
              description
              align
              media {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              productPriceDisplay {
                CAD_List_Price
                CAD_Discount
                USD_List_Price
                USD_Discount
                depositHeading
                priceHeading
                stripeId
                stripeTestId
                inventoryBreakpoints {
                  id
                  breakpointText
                  breakpointStart
                  breakpointEnd
                  textColor
                }
              }
              productDisplayEnum
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesInteractiveFeaturesZone {
            id
            core {
              anchorTag
              title
              titleColor
              subtitle
              subtitleColor
              backgroundColor
              animate
              marginTop
              marginBottom
              underlineTitle
              smallSubTitle
              titleAlign
              divider
              backgroundImage {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
            }
            description
            cards {
              id
              title
              iconType
              iconName
              description
              align
              media {
                url
                alternativeText
                imageFile {
                  childImageSharp {
                    gatsbyImageData(
                      placeholder: NONE
                      layout: FULL_WIDTH
                      quality: 80
                    )
                  }
                }
              }
              productPriceDisplay {
                CAD_List_Price
                CAD_Discount
                USD_List_Price
                USD_Discount
                depositHeading
                priceHeading
                stripeId
                stripeTestId
                inventoryBreakpoints {
                  id
                  breakpointText
                  breakpointStart
                  breakpointEnd
                  textColor
                }
              }
              productDisplayEnum
              button {
                id
                title
                type
                link {
                  __typename
                  ... on STRAPI_ComponentNavigationMainNav {
                    navItemPage {
                      id
                      slug
                    }
                    navItemLandingPage {
                      id
                      slug
                    }
                    navItemName
                  }
                  ... on STRAPI_ComponentNavigationProduct {
                    id
                    addToCart
                    product {
                      id
                      stripeId
                      stripeTestId
                      slug
                    }
                    linkText
                  }
                  ... on STRAPI_ComponentNavigationHyperlink {
                    url
                  }
                  ... on STRAPI_ComponentZonesQuiz {
                    id
                    quizId
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentZonesHeaderZone {
            id
            title
            subtitle
            alignHeader
            maxWidth
            titleColor
          }
          ... on STRAPI_ComponentZonesEmailConfirmZone {
            id
            title
            buttonText
            instructionParagraph
            resendTokenText
            redirectPage {
              id
              slug
            }
            redirectLandingPage {
              id
              slug
            }
          }
          ... on STRAPI_ComponentZonesPrivacyAndTermsZone {
            id
            policyType
          }
          ... on STRAPI_ComponentZonesTableZone {
            id
            table {
              name
              showTitle
              rows {
                title
                cells {
                  content
                }
              }
              columns {
                title
                highlighted
              }
            }
          }
        }
        parent {
          title
          slug
        }
        pages {
          title
          slug
        }
      }
      freshDeskWidget {
        freshDeskTestId
        freshDeskId
        openOnLoad
        pages {
          slug
        }
      }
      freshChatWidget {
        freshChatToken
        openOnLoad
        pages {
          slug
        }
      }
    }
  }
`
